<template>
  <v-card class="fill-height">
    <v-toolbar dense color="primary" dark>
      <v-toolbar-title>Saved Searches</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-menu</v-icon> -->
    </v-toolbar>
    <v-list dense>
        <v-list-item style="border-bottom: 1px solid silver" v-for="(item, index) in recentSearches" class="align-content-center"  dense :key="index" @click="applyFilter(index)">
          <v-list-item-content>
            <v-list-item-title>
              {{ formatFilter(item) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="my-0">
            <v-menu open-on-hover left offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              <v-card max-width="200">
                <v-card-text>
                  {{ formatFilter(item) }}
                </v-card-text>
              </v-card>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        </v-list>
    <v-card-text>
      Your last 10 saved searches appear here, click an item to load the results
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import formatFiltersAsString from '@/utilities/formatFiltersAsString'

export default {
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      recentSearches: 'filters/recentSearches'
    })
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    async applyFilter (index) {
      await this.$store.commit('filters/resetFilters')
      await this.$store.commit('filters/setFilters', this.recentSearches[index])
      this.$router.push('/catalog').catch((err) => {
        return err !== null
      })
    },
    formatFilter: formatFiltersAsString
  }
}

</script>
<style lang="scss" scoped>

</style>
