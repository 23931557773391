import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/public/promotion`

const loadPromotions = () => {
  return axios.get(api, {})
}
const loadPromotion = (id) => {
  return axios.get(api + '/' + id)
}

export default {
  loadPromotions,
  loadPromotion,
}
