<template>
  <v-card class="fill-height">
    <v-toolbar dense color="primary" dark>
      <v-toolbar-title>Quick Product Links</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col v-for="(product, index) in products" xl="4" lg="4" sm="4" cols="12" :key="index">
          <v-img class="v-clickable" @click="goToProducts(product.category)" :src="getImage(product.image)"></v-img>
          <h5 class="mt-2 text-center">{{product.category}}</h5>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>
</template>

<script>

export default {
  props: {
  },
  data () {
    return {
      imageUrl: '@/assets/',
      products: [
        { category: 'Open Edition Giclee', image: 'open_edition_giclee.jpg' },
        { category: 'Limited Edition Giclee', image: 'limited_edition_giclee.jpg' },
        { category: 'Greeting Card', image: 'greeting_cards.jpg' },
        { category: 'Magnet', image: 'magnets.jpg' },
        { category: 'Mug', image: 'mugs.jpg' },
        { category: 'Small Mounted Print', image: 'small_mounted_prints.jpg' },
        { category: 'A3 Tourist Poster', image: 'A3_tourist_posters.jpg' }
      ]
    }
  },
  computed: {
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    getImage (image) {
      return require('@/assets/' + image)
    },
    goToProducts (category) {
      const obj = {}
      obj.category = [category]
      this.$store.commit('filters/resetFilters')
      this.$store.commit('filters/setFilters', obj)
      this.$router.push('/catalog').catch((err) => {
        return err !== null
      })
    }
  }
}

</script>
