const categories = ['category', 'artist', 'regions', 'imageColour', 'subjectMatter', 'greetingCardSize', 'mountedPrintSize']

export default (item) => {
  const formatted = []
  for (const category of categories) {
    const str = item[category] && item[category].length && [item[category].join(', ')]
    str && formatted.push(str)
  }
  return formatted.join(' / ')
}
