<template>
  <v-card class="mb-2">
    <v-toolbar dense color="primary" dark>
      <v-toolbar-title>Welcome to your dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-menu</v-icon> -->
    </v-toolbar>
    <v-img src="@/assets/product_hero.jpg"></v-img>
    <!-- <v-card-title>
      Welcome to your dashboard
    </v-card-title> -->
    </v-card>
</template>

<script>

export default {
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
  }
}

</script>
