import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/clicklog`

const create = (click) => {
  return axios.post(api, { ...click })
}

export default {
  create
}
