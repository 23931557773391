<template>
  <v-card class="mb-2">
    <v-card-title>{{ item.title }}</v-card-title>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div class="my-2 ms-2" v-html="item.html.block1"></div>
      <v-avatar class="my-2" size="180" tile>
        <v-img :src="imageUrl + item.images[0].file"></v-img>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      imageUrl: `${process.env.VUE_APP_IMG_URL}/300px/`,
      promotions: [],
      show: false,
    };
  },
  computed: {},
  created: function () {},
  mounted: function () {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
  //  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.2;
}
.v-card__subtitle {
  word-break: normal;
  font-size: 14px;
  line-height: 1.2;
}
</style>
