import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/publishedartist/report`

const getCommissionPdf = (args) => {
  return axios.post(api + '/getCommissionPdf', args, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Artist_Commission${args.artist ? '_' + args.artist : ''}_${args.dateRange[0]}_${args.dateRange[1]}.pdf`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}

const loadArtistArtworks = (args) => {
  return axios.post(api + '/loadArtistArtworks', args)
}
const loadArtistCommissionByProduct = (args) => {
  return axios.post(api + '/loadArtistCommissionByProduct', args)
}
const loadArtistCommissionByArtwork = (args) => {
  return axios.post(api + '/loadArtistCommissionByArtwork', args)
}
const loadArtistSalesByArtworkAndLocation = (args) => {
  return axios.post(api + '/loadArtistSalesByArtworkAndLocation', args)
}
const loadArtistSalesByArtworkAndProduct = (args) => {
  return axios.post(api + '/loadArtistSalesByArtworkAndProduct', args)
}
const loadArtistSalesByCategory = (args) => {
  return axios.post(api + '/loadArtistSalesByCategory', args)
}

export default {
  getCommissionPdf,
  loadArtistArtworks,
  loadArtistCommissionByProduct,
  loadArtistCommissionByArtwork,
  loadArtistSalesByArtworkAndProduct,
  loadArtistSalesByArtworkAndLocation,
  loadArtistSalesByCategory,
}
